/* General */

.thumbnail-navlink {
    display:flex;
    max-width: fit-content;
    max-height: 100%;
    text-decoration: none;
    color: #232323;
}

.thumbnail-navlink:hover {
    opacity:0.85;
}

.thumbnail-img-container img {
    max-width: 100%;
}

.thumbnail-img-container video {
    max-width: 100%;
    max-height: 100%;
}

/* Style 1 */

.thumbnail-container-1 {
    display:flex;
    flex-direction: column;
    transition: 150ms;
    margin: 0 0 5% 0;
    align-items: start;
    max-width: fit-content;
}

.thumbnail-placeholder-1 {
    background-color: gray;
    width: 1600px;
    height:1000px;
}

.thumbnail-placeholder-1 img {
    pointer-events: none;
    max-height: 100%;
    max-width:100%;
}

.thumbnail-placeholder-1:hover {
    opacity:0.5;
    cursor: pointer;
}

.thumbnail-text-container-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top:5px;
    margin-left:0px;
    margin-right:0px;
}

.thumbnail-text-1 {
    display: flex;
    color: #2F2F2F;
    margin: 2% 0 1% 0;
    font-size: 30px;
    font-weight: 300;
}

.thumbnail-desc-1 {
    color: #2F2F2F;
    margin: 0 0 0 0;
    font-size: 24px;
    font-weight: 200;
}

/* Style 2 */

.thumbnail-container-2 {
    display:flex;
    flex-direction: row;
    align-self: flex-end;
    justify-self: end;
    transition: 150ms;
    max-width: fit-content;
}

.thumbnail-placeholder-2 {
    background-color: gray;
    width: 1400px;
    height:1000px;
}

.thumbnail-placeholder-2 img {
    pointer-events: none;
    max-height: 100%;
    max-width:100%;
}

.thumbnail-placeholder-2:hover {
    opacity:0.5;
    cursor: pointer;
}

.thumbnail-text-container-2 {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    margin-right: 30px;
    width:450px;
}

.thumbnail-text-2 {
    color:black;
    font-size: 30px;
    font-weight: 300;
}

.thumbnail-desc-2 {
    color:black;
    margin: 0.5% 0 1% 0;
    font-size: 24px;
    font-weight: 200;
}

@media (max-width: 1600px)
{
    .thumbnail-text-container-2 {
        margin-top:5%;
    }

    .thumbnail-text-container-1 {
        margin-left:0;
        margin-right: 0;
    }
}

@media (max-width: 1400px)
{
    .thumbnail-text-1 {
        font-size: 28px;
    }

    .thumbnail-desc-1 {
        font-size: 22px;
    }
}

@media (max-width: 900px)
{
    .thumbnail-text-1 {
        font-size: 24px;
    }

    .thumbnail-desc-1 {
        font-size: 18px;
    }
}

@media (max-width: 700px)
{
    .thumbnail-container-1 {
        width: 100%!important;
    }

    .thumbnail-text-1 {
        font-size: 30px;
    }

    .thumbnail-desc-1 {
        font-size: 20px;
    }

    .thumbnail-container-2 {
        width: 100%!important;
        flex-direction: column;
        margin: 0 0 0% 0;
    }

    .thumbnail-text-container-1 {
        width:100%;
        margin: 0 0 0 0;
    }

    .thumbnail-text-container-2 {
        width:100%;
        margin-bottom: 1%;
    }
}