/* Section Mid */
.workSectionMid-container img {
    max-width: 100%;
    max-height: 100%;
}

.workSectionMid-container {
    display:flex;
    flex-direction: row;
    margin-top: 10px;
    width:100%;
    height: 1500px;
}

.workSectionMid-left {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:60%;
    height:100%;
}

.workSectionMid-right {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:200px;
    width:50%;
}

/* Section End */

.workSectionEnd-container img {
    max-width: 100%;
    max-height: 100%;
}

.workSectionEnd-container {
    display:flex;
    flex-direction: row;
    /* background-color: red; */
    width:100%;
    height: 1200px;
    margin-bottom: 4%;
}

.workSectionEnd-left {
    /* background-color: aqua; */
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width:60%;
    height:100%;
}

.workSectionEnd-right {
    /* background-color: yellow; */
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:40%;
}

/* Work Footer */
.workFooter-container {
    background-color: #F1F1F1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height:800px;
    margin-top:100px;
}

.workFooter-email-text {
    margin: 200px 0px 0px 200px;
    font-size: 30px;
    line-height: 1.5;
}

.workFooter-text-2 {
    margin-top:2%;
    font-size: 56px;
    text-decoration: underline;
}

.workFooter-links {
    margin-top:auto;
    margin-bottom:100px;
    margin-right: 80px;
    display: flex;
    gap: 38px;
    font-weight: 400;
    font-size:24px;
    justify-self: flex-end;
    align-self: flex-end;
}

.workFooter-links a {
    text-decoration: none;
    color:#000000;
}


/* Responsive */
@media (max-width: 2000px) {
    .workSectionEnd-container {
        height:1000px;
    }
}


@media (max-width: 1800px) {
    .workSectionMid-container {
        height:1400px;
    }
    
    .workSectionEnd-container {
        height:900px;
    }  
}

@media (max-width: 1550px) {
    .workSectionMid-container {
        height:100%;
        margin-top:6%;
    }

    .workSectionEnd-container {
        height:650px;
    }

    .workFooter-container {
        height:600px;
        margin-top:3%;
    }

    .workFooter-email-text {
        margin: 10% 0px 0px 15%;
        font-size: 24px;
        line-height: 1.5;
    }

    .workFooter-text-2 {
        margin-top:2%;
        font-size: 42px;
        text-decoration: underline;
    }

    .workFooter-links {
        margin-bottom:10%;
        margin-right: 5%;
        display: flex;
        gap: 10%;
        font-weight: 400;
        font-size:24px;
        width: 30%;
    }
    
}

@media (max-width: 1100px) {
    .workFooter-email-text {
        margin: 15% 0px 0px 15%;
    }

    .workFooter-links {
        font-size: 18px;
    } 
}

@media (max-width: 700px) {
    .workFooter-email-text {
        margin: 10% 0px 0px 5%;
        font-size: 16px;
        line-height: 1.5;
    }

    .workFooter-text-2 {
        margin-top:1%;
        font-size: 23px;
        text-decoration: underline;
    }

    .workFooter-container {
        margin-top: 10%;
        height: 300px;
    }

    .workFooter-links {
        width:40%;
        font-size:12px;
    }

    .workSectionMid-container {
        flex-direction: column;
        height: auto;
        gap:5%;
        margin-top: 10%;
        margin-bottom: 25%;
    }

    .workSectionMid-left {
        width:100%;
    }

    .workSectionMid-right {
        width:100%;
        gap:5%;
    }

    .workSectionEnd-container {
        flex-direction: column;
        height: auto;
        gap:5%;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .workSectionEnd-left {
        width:100%;
    }

    .workSectionEnd-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width:85%;
        align-self: center;
    }
}